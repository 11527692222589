import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/faq.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* <Info>Didn't find an answer? Explore the <a href="https://help.starswap.xyz/">help guides</a> or join the <a href="https://discord.gg/FCfyBSbCU5">community Discord</a> to get support</Info> */
    }
    <h3 {...{
      "id": "why-must-starswap-be-deployed-on-the-starcoin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#why-must-starswap-be-deployed-on-the-starcoin",
        "aria-label": "why must starswap be deployed on the starcoin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why must Starswap be deployed on the Starcoin?`}</h3>
    <p>{`This is because Starcoin has an open ecosystem and secure architecture, which guarantees that Starswap is an easy and secure DEX for trading and exchanging, making more user-friendly decentralized finance (DeFi).`}</p>
    <p>{`More details are found by `}<a parentName="p" {...{
        "href": "https://starcoin.org/en/developer/blog/what_is_starcoin/"
      }}>{`clicking the following`}</a></p>
    <h3 {...{
      "id": "what-is-stc-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-stc-token",
        "aria-label": "what is stc token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is STC token?`}</h3>
    <p>{`STC is a native coin on the Starcoin, which can be obtained by swapping, mining, trading, or participating in airdrops`}</p>
    <p>{`More details are found by `}<a parentName="p" {...{
        "href": "https://starcoin.org/en/overview/economy_whitepaper/"
      }}>{`clicking the following`}</a></p>
    <h3 {...{
      "id": "what-is-star-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-star-token",
        "aria-label": "what is star token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is STAR token?`}</h3>
    <p>{`STAR is a token on the Starswap for platform governance, which can be obtained by exchanging in the Swap or by staking the LP token in the Farm.`}</p>
    <p>{`More details are described in the `}<a parentName="p" {...{
        "href": "https://docs.starswap.xyz/tokenomics"
      }}>{`Tokenomics`}</a>{`.`}</p>
    <h3 {...{
      "id": "what-is-fai-token？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-fai-token%EF%BC%9F",
        "aria-label": "what is fai token？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is FAI token？`}</h3>
    <p>{`FAI is the stablecoin on the Starcoin, which value is pegged to 1 USD, backed by crypto-asset $STC as collateral. It can be mined by staking STC (`}<a parentName="p" {...{
        "href": "https://fai.bfly.finance/"
      }}>{`fai.bfly.finance`}</a>{`) or exchanged on the Swap (`}<a parentName="p" {...{
        "href": "https://starswap.xyz/"
      }}>{`starswap.xyz`}</a>{`).`}</p>
    <h3 {...{
      "id": "does-starswap-support-usdt-or-other-stablecoins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#does-starswap-support-usdt-or-other-stablecoins",
        "aria-label": "does starswap support usdt or other stablecoins permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h3">{`Does Starswap support USDT or other stablecoins?`}</strong></h3>
    <p>{`Starswap supports USDT(Starcoin)/USDC(Aptos) after the cross-chain bridge goes online.`}</p>
    <h3 {...{
      "id": "is-there-a-cross-chain-bridge-to-transfer-my-usdt-assets-to-starswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#is-there-a-cross-chain-bridge-to-transfer-my-usdt-assets-to-starswap",
        "aria-label": "is there a cross chain bridge to transfer my usdt assets to starswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h3">{`Is there a cross-chain bridge to transfer my USDT assets to Starswap?`}</strong></h3>
    <p>{`The cross-chain bridge for Starcoin is under development and is scheduled to launch in April 2022. Starswap will add USDT and other popular assets into the supported tokens.`}</p>
    <h3 {...{
      "id": "is-starswap-secure",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#is-starswap-secure",
        "aria-label": "is starswap secure permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Is Starswap secure?`}</h3>
    <p>{`Starswap is absolutely secure. Starswap is deployed on the Starcoin, which is highly secure and reliable by using the PoW consensus mechanism to build a two-layer network ecosystem for the on-chain governance, and adopting the extremely secure MOVE smart contract language.`}</p>
    <h3 {...{
      "id": "how-does-starswap-contract-upgrade",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-does-starswap-contract-upgrade",
        "aria-label": "how does starswap contract upgrade permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h3">{`How does Starswap contract upgrade?`}</strong></h3>
    <p>{`Starswap contract upgrades use a time lock mechanism. Submit an upgrade plan before each update, and the upgrade can be performed after 24 hours. The next version will take the form of a DAO-based community vote upgrade.`}</p>
    <h3 {...{
      "id": "what-are-the-main-risks-of-starswap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-are-the-main-risks-of-starswap",
        "aria-label": "what are the main risks of starswap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What are the main risks of Starswap?`}</h3>
    <p>{`Starswap has the risk of impermanent losses or divergence loss during the on-chain trading process including mining, exchanging, and staking liquidity`}</p>
    <h3 {...{
      "id": "is-starswap-open-source",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#is-starswap-open-source",
        "aria-label": "is starswap open source permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Is Starswap open source?`}</h3>
    <p>{`Yes. Please visit the Github:`}<a parentName="p" {...{
        "href": "https://github.com/Elements-Studio/starswap-core"
      }}>{`https://github.com/Elements-Studio/starswap-core`}</a></p>
    <h3 {...{
      "id": "how-do-i-participate-in-the-dao-management",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-participate-in-the-dao-management",
        "aria-label": "how do i participate in the dao management permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I participate in the DAO management?`}</h3>
    <p>{`You can use the STAR token for the platform governance for community voting to participate in the DAO management.`}</p>
    <h3 {...{
      "id": "what-should-i-do-if-the-transaction-waits-too-long-or-has-no-response",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-should-i-do-if-the-transaction-waits-too-long-or-has-no-response",
        "aria-label": "what should i do if the transaction waits too long or has no response permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What should I do if the transaction waits too long or has no response?`}</h3>
    <p>{`First, please try to disconnect your wallet if connected), and then re-access your wallet.`}</p>
    <p>{`refesh your page on the Chrome browser.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      